.merchant-cashback-wrapper__disable-sticky {
    .semi-collapse-header[aria-expanded="true"] {
        position: unset !important;
    }
}

.merchant-cashback-wrapper {
    .merchant-logo-wrapper {
        img {
            object-fit: contain;
            background: white;
            padding: 4px;
            box-sizing: border-box;
        }
    }

    .semi-table-row-cell,
    .semi-table-empty,
    .semi-table-row-head,
    .semi-table-pagination-info,
    .semi-page-item {
        font-family: "Poppins", sans-serif;
        font-weight: 600;
    }

    .collapse-panel-wrapper__shopback .semi-collapse-header[aria-expanded="true"] {
        color: black;
        background: #fde7e2;
        position: sticky;
        top: 8px;
        z-index: 10;

        .semi-collapse-header-icon {
            color: black;
        }
    }

    .collapse-panel-wrapper__cashrewards .semi-collapse-header[aria-expanded="true"] {
        color: black;
        background: #eae2f4;
        position: sticky;
        top: 8px;
        z-index: 10;

        .semi-collapse-header-icon {
            color: black;
        }
    }

    .collapse-panel-wrapper__topcashback .semi-collapse-header[aria-expanded="true"] {
        color: black;
        background: #e4efea;
        position: sticky;
        top: 8px;
        z-index: 10;

        .semi-collapse-header-icon {
            color: black;
        }
    }

    .semi-table-tbody > .semi-table-row {
        background: transparent;
    }
}