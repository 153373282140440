.reward-flight-detail-wrapper {
    .airport-separator-img {
        cursor: pointer;
        width: 32px;
        height: 32px;

        &:hover img {
            transform: scaleX(-100%);
            transition: 0.2s;
        }
    }

    .popular-destination-card-wrapper {
        cursor: pointer;

        &:hover {
            transform: scale(1.015);
            transition: .2s;        
        }
    }
}