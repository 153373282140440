.stock-checker-wrapper {
    .semi-input-large {
        font-size: 15px !important;
    }

    .semi-list-header {
        padding: 8px 16px;
    }
}

.store-popover-wrapper {
    .semi-descriptions-value {
        font-size: 11px;
    }

    .semi-descriptions-key {
        font-size: 11px;
    }

    .semi-descriptions-item {
        padding-bottom: 0;
    }
}