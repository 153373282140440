.App_mobile {
    .gift-card-wrapper {
        padding: 0 12px !important;
    }

    .gift-card-filter-wrapper {
        height: 86px !important;
    }
}

.gift-card-wrapper {
    padding: 0 16px;
    display: flex;
    justify-content: center;

    .semi-table-row-cell,
    .semi-table-empty,
    .semi-table-row-head,
    .semi-table-pagination-info,
    .semi-page-item {
        font-family: "Poppins", sans-serif;
        font-weight: 600;
    }
    
    .checkbox-filter-wrapper {
        display: flex;
        align-items: center;
        border: 1px solid rgb(53, 55, 59);
        padding: 4px 10px;
        border-radius: 16px;
        background: white;
        white-space: nowrap;

        .semi-select-arrow-empty {
            display: none;
        }

        .semi-select-selection {
            margin-left: 0 !important;
        }

        .semi-select-content-wrapper {
            margin: 0 6px;
        }

        .semi-select-prefix {
            margin: 0;
        }
    }

    .semi-input-wrapper {
        // background-color: transparent !important;

        input {
            font-family: "Poppins", sans-serif !important;
        }

        input::placeholder {
            font-family: "Poppins", sans-serif !important;
        }

        &:active {
            background-color: transparent !important;
        }
    }

    .semi-input-wrapper-focus {
        border: none !important;
    }

    // .semi-table-pagination-outer {
    //     // background-color: #f2f3f5;
    //     position: sticky;
    //     bottom: 0;
    // }

    .gift-card-filter-wrapper {
        background-color: #f2f3f5;
        position: sticky;
        top: 0;
        padding: 8px 0;
        z-index: 101;
        height: 48px;
        box-sizing: border-box;
    }
}

.gift-card-promotion-detail-modal-wrapper {
    .semi-modal-content {
        // padding: 0
    }
}

.App_dark {
    .mobile-giftcard-wrapper {
        background: #35363c;
    }
}
