.similar-cashback-offer-wrapper {
    .merchant-logo-wrapper {
        img {
            object-fit: contain;
            background: white;
            padding: 4px;
            box-sizing: border-box;
        }
    }

    .cashback-card-wrapper {
        cursor: pointer;
    
        &:hover {
            transform: scale(1.015);
            transition: .2s;
        }
    }
}