.catalogue-management-wrapper {

    [aria-expanded="true"] {
        background: var(--semi-color-primary-light-default) !important;

        &:hover > td {
            background: none !important;
        }
    }

    .semi-table-row-expand {
        & > .semi-table-row-cell {
            padding: 0;
            padding-left: 30px;
        }
    }   
}