.navbar-wrapper {
  position: relative;
  z-index: 103;

  .semi-navigation-item-normal {
    font-family: "Poppins", sans-serif;
    font-weight: 600;
  }

  .semi-navigation-item-normal {
    color: hsla(0,0%,100%,0.8) !important;
  }

  .semi-navigation-item:hover {

    .semi-navigation-item-text, .semi-icon {
      color: white !important;
    }
  }

  .semi-navigation-item-selected {
    .semi-navigation-item-text, .semi-icon {
      color: #ff7900 !important;
    }
  }

  .semi-icon {
    color: hsla(0,0%,100%,0.8) !important;
  }
}

.App_pc {
  .navbar-wrapper {
    .semi-navigation-inner {
      position: relative;
      overflow: auto;
      scrollbar-width: none; /* For Firefox */
      -ms-overflow-style: none;  /* For Internet Explorer and Edge */
    }

    .semi-navigation-inner::-webkit-scrollbar {
      display: none; /* For Chrome, Safari, and Opera */
    }

    .semi-navigation-header {
      position: sticky;
      left: 0;
      background: rgb(53, 55, 59);
      margin-right: 12px !important;
      padding-right: 12px !important;
    }
  }
}

.App_mobile {
  .semi-navigation {
    padding-left: 16px !important;
    padding-right: 16px !important; 
  }

  .semi-navigation-footer {
    padding: 0
  }
}

.mobile-navbar-wrapper {
  height: '100%';

  .semi-navigation-vertical {
    border-right: none !important;
    padding: 0 !important;
  }

  .semi-navigation {
    background: transparent !important;
  }

  .semi-navigation-item-normal {
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    font-size: 20px;
  }

  .semi-navigation-item-icon {
    font-size: 20px !important;
  }
}

.App_mobile .main-container {
  padding: 0 12px !important;
}

.App_inapp {
  .t-tab-bar {
    border-top: 1px solid #eaeaea !important;
  }
}

.App_light {
  background-color: #f2f3f5;
}

.App_dark {
  background-color: rgb(22, 22, 26);

  .responsive-background {
    background-color: rgb(22, 22, 26) !important;
  }

  .responsive-background-secondary {
    background: rgba(53, 54, 60, 1) !important;

    .t-cell {
      background: rgba(53, 54, 60, 1) !important;
    }
  }

  .responsive-border {
    border-color: rgba(255,255,255,0.08) !important;

    &::after,
    &::before {
      border-color: rgba(255,255,255,0.08) !important;
    }
  }

  .responsive-text {
    color: white !important;

    &__opposite {
      color: black !important;
    }

    .t-cell__left-icon,
    .t-cell__title {
      color: white;
    }
  }

  .semi-page-rest-item {
    color: white;
  }

  .t-tab-bar {
    background: rgb(22, 22, 26) !important;
    border-color: rgba(255,255,255,0.08) !important;

    .t-tab-bar-item__text,
    .t-tab-bar-item__icon {
      color: rgba(249,249,249,.8)
    }

    .t-is-checked {
      .t-tab-bar-item__text,
      .t-tab-bar-item__icon {
        color: rgb(84,169,255)
      }
    }
  }
}

.driver-popover {
  & * {
    font-family: "Poppins", sans-serif !important;
  }

  .driver-popover-title,
  .driver-popover-description,
  .driver-popover-progress-text,
  .driver-popover-next-btn,
  .driver-popover-prev-btn {
      font-family: "Poppins", sans-serif !important;
  }

  .driver-popover-title {
      letter-spacing: -0.5px;
      font-size: 16px;
  }

  .driver-popover-description {
      font-size: 13px;
  }
}

.App_dark {
  .driver-popover-title,
  .driver-popover-description {
      color: white;
  }

  .driver-popover-arrow-side-left {
      border-left-color: #35363c !important;
  }
  
  .driver-popover-arrow-side-right {
      border-right-color: #35363c !important;
  }
  
  .driver-popover-arrow-side-top {
      border-top-color: #35363c !important;
  }
  
  .driver-popover-arrow-side-bottom {
      border-bottom-color: #35363c !important;
  }

  .driver-popover-close-btn {
      color: #e8e8e8;
  }

  .driver-popover-close-btn:hover {
      color: white;
  }
}