.gift-card-summary-wrapper,
.toastui-editor-contents {
    font-family: "Poppins", sans-serif !important;
    font-weight: 400;
    padding-bottom: 16px;

    h1,
    h2,
    h3,
    h4,
    h5,
    p {
        font-family: "Poppins", sans-serif !important;
    }

    h1,
    h2,
    h3,
    h4,
    h5 {
        font-family: "Poppins", sans-serif !important;
        font-weight: 700 !important;
    }

    li {
        font-size: 14px;
        margin-bottom: 4px;
    }

    ul {
        padding-left: 20px;
        margin-top: 6px;
    }

    h2 {
        font-size: 24px !important;
        margin-bottom: 0 !important;
        letter-spacing: -1px;
        display: none;
    }

    h3 {
        font-size: 18px !important;
        margin-bottom: 6px !important;
        letter-spacing: -0.5px;
    }

    p {
        margin-bottom: 8px;
        font-size: 14px;
    }

    strong {
        font-weight: 600;
    }

    .semi-table-pagination-outer {
        display: none !important;
    }

    .semi-table-row {

        &>th:nth-child(2),
        &>td:nth-child(2) {
            min-width: 400px;
        }

        &>th:first-child,
        &>td:first-child {
            width: 100px;
            position: sticky;
            left: 0;
            z-index: 10;
            background: var(--semi-color-bg-1);
            border-right: 2px solid var(--semi-color-border);
        }

        &>th:last-child,
        &>td:last-child {
            width: 100px;
        }

        li {
            font-weight: 400;
        }

        a {
            color: var(--semi-color-primary) !important;
            text-decoration: none !important;
        }

        ul {
            margin: 0;

            &>li:last-child {
                margin-bottom: 0 !important;
            }
        }
    }
}