.App_mobile {
    .cashback-wrapper {
        padding: 0 12px !important;
    }

    .cashback-filter-wrapper {
        height: 86px !important;
    }
}

.cashback-wrapper {
    padding: 0 16px;
    display: flex;
    justify-content: center;

    .semi-table-row-cell,
    .semi-table-empty,
    .semi-table-row-head,
    .semi-table-pagination-info,
    .semi-page-item {
        font-family: "Poppins", sans-serif;
        font-weight: 600;
    }

    .checkbox-filter-wrapper {
        display: inline-block;
        border: 1px solid rgb(53, 55, 59);
        padding: 4px 10px;
        border-radius: 16px;
        background: white;
    }

    .cashback-filter-wrapper {
        background-color: #f2f3f5;
        position: sticky;
        top: 0;
        padding: 8px 0;
        z-index: 101;
        height: 48px;
        box-sizing: border-box;
    }
    
    // .merchant-logo-img-wrapper {
    //     display: block;
    //     max-width: 100%;
    //     height: auto;
    //     -webkit-mask-image: radial-gradient(circle, rgba(0, 0, 0, 1) 20%, rgba(0, 0, 0, 0) 100%);
    //     mask-image: radial-gradient(circle, rgba(0, 0, 0, 1) 20%, rgba(0, 0, 0, 0) 100%);
    // }

    .merchant-logo-wrapper {
        img {
            object-fit: contain;
            background: white;
            padding: 4px;
            box-sizing: border-box;
        }
    }
}

.App_dark {
    .mobile-cashback-wrapper {
        background: #35363c;
    }
}
