.cashback-detail-wrapper {
    .merchant-logo-wrapper {
        img {
            object-fit: contain;
            background: white;
            padding: 4px;
            box-sizing: border-box;
        }
    }

    .logo-medium-wrapper .semi-avatar-bottom_slot span {
        position: relative;
        top: -2px !important;
    }
}