.airport-map-wrapper {
    .dot {
        width: 18px;
        height: 18px;
        background-color: #3498db;
        border-radius: 50%;
        position: absolute;
        animation: fadeOut 2s ease-out infinite;
        z-index: 1;
        top: 0;
        left: 0
    }

    @keyframes fadeOut {
        0% {
            opacity: 0.5;
            transform: scale(1);
        }

        100% {
            opacity: 0;
            transform: scale(3);
        }
    }

    .destination-airport-node-wrapper {
        cursor: pointer;

        &:hover {
            transform: scale(1.4)
        }
    }

    .airport-marker-departure {
        z-index: 1;
    }
}

.reward-flight-info-card-wrapper {
    overflow: hidden;

    background-color: white;
    padding: 12px 16px;
}

.App_pc {
    .reward-flight-info-card-wrapper {
        position: absolute;
        transform-origin: top right;
        // top: 120px;
        // right: 24px;
        // animation: fadeIn 0.2s linear;
        top: -4px;
        z-index: 100;
        left: 22px;
        width: 220px;
        border-radius: 8px;

        .description-item-value-wrapper {
            font-size: 14px;
        }
    }

    .reward-flight-info-card-wrapper__left {
        position: absolute;
        transform-origin: top right;
        z-index: 100;
        left: -260px;
    }
}

.App_mobile .reward-flight-info-card-wrapper {
    border-radius: 12px 12px 0 0;
}