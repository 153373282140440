.shopping-list-wrapper {
    position: relative;

    .t-tabs__nav-item-btn {
        font-family: "Poppins", sans-serif !important;
        font-weight: 600;
    }

    .semi-tabs-bar {
        padding: 0 24px;
    }

    .semi-tabs-tab {
        padding-bottom: 8px !important;
    }
}

.shopping-list-footer-wrapper {
    z-index: 5;
    background: white;
    width: 100%;
    position: sticky;
    bottom: 0;
    padding: 8px 12px 8px 8px;
    box-sizing: border-box;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 0px 5px 0px;
    border-radius: 4px 4px 0 0;

    .semi-checkbox-inner, .semi-checkbox-inner-display {
        width: 20px;
        height: 20px;
    }

    .semi-checkbox-inner-display {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .semi-checkbox-inner-display {
        width: 20px;
        height: 20px;
        display: flex;
        align-items: center;
        justify-content: center;

        .semi-icon {
            font-size: 20px;
        }
    }

    .semi-checkbox {
        border-radius: 2px;
    }
}