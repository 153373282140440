html, body {
  margin: 0;
  font-weight: 600;
  font-family: "Poppins", sans-serif !important;
  overflow: hidden;
  /* font-weight: 400; */
}

.font-weight-regular {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.font-weight-bold {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-style: normal;
}

.font-weight-black {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-style: normal;
}

.text-overflow {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.text-overflow-max-two-lines {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;  
  overflow: hidden;
  text-overflow: ellipsis;
}

.cursor-pointer {
  cursor: pointer;
}

.semi-button-content,
.semi-tabs-tab,
.semi-tag-content,
.semi-modal-title,
.semi-select-option,
.semi-select-selection-text,
.semi-table-row > th,
.semi-table-row > td,
.semi-sidesheet-title,
.semi-radio-addon-buttonRadio,
.semi-form-field-label-text,
.semi-dropdown-item,
.semi-descriptions-key,
.semi-descriptions-value,
.semi-input-textarea,
.semi-toast-content-text,
.semi-navigation-item-text,
.semi-table-pagination-info,
.semi-popconfirm-header-title,
.semi-popconfirm-body,
.semi-radio,
.semi-typography,
.semi-checkbox-content,
.semi-tooltip-content,
.semi-upload-drag-area-main-text,
.semi-collapse-header,
.semi-notification-notice-content,
.semi-datepicker-quick-control-header,
.semi-select-group,
.semi-descriptions-value,
.semi-descriptions-key,
.semi-cascader-option {
  font-family: "Poppins", sans-serif !important;
}

input,
input::placeholder,
.semi-input-suffix,
.semi-input-prefix {
  font-family: "Poppins", sans-serif !important;
}

.custom-hover-background:hover {
  background-color: rgb(230,232,234);
}