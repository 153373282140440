.hotel-management-wrapper {
    .semi-table-pagination-outer {
        position: sticky;
        bottom: 0;
        background-color: #f2f3f5;
        min-height: 48px !important;

        .semi-table-pagination-info {
            padding-left: 16px
        }
    }
}

.App_dark {
    .hotel-management-wrapper {
        .semi-table-pagination-outer {
            background-color: rgb(22, 22, 26) !important;
        }
    }
}