.main-container {
    padding: 0 24px;
    display: flex;
    justify-content: center;
    gap: 12px;
    position: relative;

    .content-wrapper {
        max-width: 1000px;
        width: 100%;
        position: relative;
    }

    .semi-radioGroup {
        gap: 0;
    }

    .semi-radio-cardRadioGroup_checked {
        border: none !important;
        
        .semi-radio-addon {
            color: var(--semi-color-primary) !important;
        }
    }

    .semi-radio {
        font-family: "Poppins", sans-serif;
        font-weight: 600;
        font-style: normal;
        padding: 10px 17px;
    }
}

.filter-wrapper {
    .semi-input-wrapper {
        background-color: transparent !important;

        input {
            font-family: "Poppins", sans-serif !important;
        }

        input::placeholder {
            font-family: "Poppins", sans-serif !important;
        }

        &:active {
            background-color: transparent !important;
        }
    }

    .semi-input-wrapper-focus {
        border: none !important;
    }
}