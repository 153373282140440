.reward-hotels-wrapper {
    .semi-select-prefix {
        white-space: nowrap;
    }

    .semi-tag-content {
        width: fit-content !important;
    }
}

.reward-hotels-filter-date-picker-wrapper {
    .semi-datepicker-quick-control-left-content {
        grid-template-columns: repeat(1, 120px) !important;
    }
}

.reward-hotels-filter-brand-wrapper {
    .semi-select-group {
        padding-top: 8px !important;
    }

    .semi-select-option-list > .semi-select-group:first-child {
        padding-top: 4px !important;
    }
}

.reward-hotels-filter-sidesheet-wrapper {
    .semi-sidesheet-header {
        display: none;
    }
}