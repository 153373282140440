.hotel-match-sidesheet-wrapper {
    .semi-sidesheet-header {
        display: none;
    }

    .semi-collapse-content {
        padding: 0 !important;
    }

    .semi-collapse-header {
        margin: 0;
        padding: 0;
        padding-right: 8px;
    }

    .semi-collapse-item {
        border-bottom: none !important;
    }


    .price-legend-wrapper {
        margin-left: 2px;
    }

    .hotel-summary-wrapper {
        animation: slide-fade-in 0.5s;

        &:hover {
            transform: scale(1.02);
            transition: .3s;
        }
    }

    @keyframes slide-fade-in {
        0% {
            transform: translateX(50px);
            opacity: 0;
        }

        100% {
            transform: translateX(0);
            opacity: 1
        }
    }

    .semi-table-row {
        & > td:first-child {
            // padding-left: 8px !important;
            padding: 0;
        }

        & > td {
            padding: 0 !important;
            height: 100%;
        }

        & > th:nth-child(2) {
            padding-left: 0px !important;
        }

        & > th {
            padding: 8px !important;
        }
    }
}

.App_mobile {
    .semi-collapse-header-icon {
        display: none !important;
    }

    .semi-collapse-header {
        padding-right: 0;
    }
}