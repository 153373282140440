.flight-price-calendar-wrapper {
    .semi-calendar-month-header span,
    .semi-calendar-month-date, .semi-calendar-today-date {
        font-family: "Poppins", sans-serif !important;
    }

    .semi-calendar-month-date, .semi-calendar-today-date {
        font-size: 16px;
        font-weight: 600;
        letter-spacing: -0.5px;
        // position: relative;
        // top: 2px;
        // right: 2px;
    }

    .semi-calendar-event-item {
        height: unset !important;
    }
}