.price-history-pane-wrapper {
    /* Define the animation */
    @keyframes expandWidth {
        0% {
            width: 0%;
        }

        100% {
            width: var(--dynamic-width);
        }
    }

    /* Apply the animation to the div */
    .rect {
        width: 0%;
        animation: expandWidth .3s ease-out forwards;
        --dynamic-width: 50%; /* Set the dynamic width here */
    }
}