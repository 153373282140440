.dropdown-header-wrapper {
    height: 60px;
    display: flex;
    justify-content: center;
    background-color: #f2f3f5;
    align-items: center;
    width: 100%;
    position: sticky;
    top: 0;
    z-index: 100;
    padding: 0 24px;
    box-sizing: border-box;

    // &__long_run {
    //     height: 47px;
    // }

    .header-wrapper {
        max-width: 1000px;
        width: 100%;
        padding-top: 8px;
    }
}

.catalogue-dropdown-item-wrapper {
    .semi-dropdown-item {
        max-width: calc(100vw - 24px);
        box-sizing: border-box;
    }
}

.App_mobile {
    .dropdown-header-wrapper {
        padding: 0 12px;
    
        .header-wrapper {
            max-width: 1000px;
            width: 100%;
            padding-top: 8px;
        }
    }
    
}

.tablist-wrapper {
    height: 47px;
    background-color: white;
    display: flex;
    justify-content: center;
    width: 100%;
    border-bottom: 1px solid rgba(var(--semi-grey-9), .08);
    position: sticky;
    top: 0;
    z-index: 100;

    .semi-tabs {
        max-width: 1000px;
        width: 100%;
        padding-left: 24px;
        padding-right: 24px;
        box-sizing: content-box;
    }

    .semi-tabs-bar {
        border-bottom: none !important;
    }

    .semi-tabs-tab {
        padding-top: 13px !important;
        padding-bottom: 11px !important;
        font-family: "Poppins", sans-serif;
        font-weight: 600;
    }
}

.tablist-wrapper-collapse .semi-tabs {
    padding-right: 0 !important;
    padding-left: 0 !important;
}