.hotel-map-wrapper {
    .dot {
        width: 18px;
        height: 18px;
        background-color: #3498db;
        border-radius: 50%;
        position: absolute;
        animation: fadeOut 2s ease-out infinite;
        z-index: 1;
        top: 0;
        left: 0
    }

    @keyframes fadeOut {
        0% {
            opacity: 0.5;
            transform: scale(1);
        }

        100% {
            opacity: 0;
            transform: scale(3);
        }
    }

    .destination-airport-node-wrapper {
        cursor: pointer;

        &:hover {
            transform: scale(1.4)
        }
    }
}