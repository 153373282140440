.system-status-wrapper {
    padding: 0 24px;
    display: flex;
    justify-content: center;

    .content-wrapper {
        max-width: 1000px;
        width: 100%;
    }

    .semi-table-row-cell {
        font-family: "Poppins", sans-serif;
        font-weight: 600;
    }
}