.fuel-map-wrapper {
    .semi-tag .semi-avatar img {
        object-fit: contain;
        background-color: white;
        // width: 24px;
        // height: 24px;
    }

    .semi-sidesheet-header {
        display: none;
    }

    .semi-select-prefix {
        font-weight: 600;
        font-size: 14px;
        white-space: nowrap;
        color: grey;
    }
    
    .fuel-station-marker-wrapper {
        &:hover {
            transform: scale(1.2);
            transition: 0.2s;
        }

        &__active {
            transform: scale(1.2);
        }
    }

    .fuel-map-statistics-wrapper {
        .semi-descriptions-item {
           padding-bottom: 4px;
        }

        .semi-descriptions-key, .semi-descriptions-value {
            font-weight: 600;
        }
    }
}

.fuel-station-table-wrapper {
    .semi-table-thead th {
        height: 32px !important;
    }
}

