.retailer-explorer-wrapper {

    .semi-table-row-cell,
    .semi-table-empty,
    .semi-table-row-head,
    .semi-table-pagination-info,
    .semi-page-item {
        font-family: "Poppins", sans-serif;
        font-weight: 600;
    }

    .semi-collapse-item {
        border-bottom: none !important;
    }

    .semi-table-row-cell {
        padding-top: 4px !important;
        padding-bottom: 4px !important;
    }

    .semi-table-row > td:nth-child(2) {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }

    tbody .retailer-name-wrapper {
        background: white !important;
        background-image: none !important;
    }
}