.cashback-calculator-wrapper {
    // .semi-form-field {
    //     padding-right: 0 !important;
    // }

    // .semi-form {
    //     .semi-form-field:first-child .semi-input-wrapper {
    //         border-radius: 4px 0 0 4px !important;
    //         width: 100px;

    //     }

    //     .semi-form-field:last-child .semi-input-wrapper {
    //         border-radius: 0 4px 4px 0 !important;
    //         width: 100px;
    //     }
    // }

    label {
        font-family: "Poppins", sans-serif;
    }
}