.gift-card-promotion-detail-body-wrapper {
    position: relative;
    width: 100%;

    .section-header {
        position: sticky;
        top: -24px;
        background: white;
        padding: 12px 0;
        z-index: 101;
    }

    .semi-table-row-cell,
    .semi-table-empty,
    .semi-table-row-head,
    .semi-table-pagination-info,
    .semi-page-item {
        font-family: "Poppins", sans-serif;
        font-weight: 600;
    }

    // .semi-table-row-cell, .semi-table-row-head {
    //     padding-top: 4px !important;
    //     padding-bottom: 4px !important;
    // }

    .semi-tabs {
        // position: sticky;
        // top: 0;
        background: white;
        z-index: 10;
    }

    .semi-tabs-tab {
        padding: 0 !important;
        padding-bottom: 8px !important;
    }

    .semi-tabs-bar-arrow button {
        position: relative;
        top: -4px;
    }

    .semi-table-fixed {
        overflow: hidden;
    }

    tbody .retailer-name-wrapper {
        background: white !important;
        background-image: none !important;
    }
}

.App_mobile .gift-card-promotion-detail-body-wrapper {
    margin-bottom: 0 !important;
}

// .App_dark {
//     .gift-card-promotion-detail-body-wrapper {
//         .semi-tabs {
//             background: rgba(35, 36, 41, 1) !important;
//         }
//     }
// }