

.catalogue-render-wrapper {
    .semi-list-header {
        padding: 2px 4px;
    }

    .catalogue-item-list-wrapper {
        display: grid;
        grid-template-columns: repeat(4, 1fr); /* 2 columns with equal width */
        // gap: 8px; /* Space between grid items */
        // border: 1px solid black; /* Border around the entire grid */
        // padding: 10px; /* Space inside the grid border */
    
        & > div {
            border: 0.5px solid #eaeaea; /* Border around each grid item */
            padding: 4px;
            display: flex;
            align-items: center;
        }
    
        .semi-list-header {
            padding: 2px 4px;
        }
    }
    
    .catalogue-item-name-wrapper > span {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2; /* Number of lines to display */
        overflow: hidden;
        text-overflow: ellipsis;
    }
}