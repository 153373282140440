.similar-product-sidesheet-wrapper {
    .catalogue-item-banner {
        height: 30px !important;
        line-height: 30px !important;
        font-size: 14px !important;
    }
}

.product-card-wrapper {
    .semi-checkbox-inner, .semi-checkbox-inner-display {
        width: 20px;
        height: 20px;
    }

    // .semi-checkbox-inner {
    //     background-color: white;
    // }

    .semi-checkbox-inner-display {
        width: 20px;
        height: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: inset 0 0 0 1px var(--semi-color-focus-border);

        .semi-icon {
            font-size: 20px;
        }
    }

    .semi-checkbox {
        border-radius: 2px;
    }

    .open-in-app-button .semi-button-content-right {
        display: none !important;
    }

    .semi-image-img {
        object-fit: contain;
    }
}