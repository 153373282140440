.App_dark .map-search-input-wrapper {
    .semi-icon {
        color: black;
    }

    input {
        color: black;
        
        &::placeholder {
            color: rgb(167,171,176);
        }
    }
}

.reward-flight-wrapper {
    .semi-input-wrapper {
        input {
            font-family: "Poppins", sans-serif !important;
        }

        input::placeholder {
            font-family: "Poppins", sans-serif !important;
        }
    }

    .semi-table-row-head {
        font-family: "Poppins", sans-serif !important;
    }
}

.App_mobile {
    .reward-flight-wrapper .semi-table-row>td:first-child {
        width: 350px !important;
    }

    .reward-flight-wrapper .semi-table-row>th:first-child {
        width: 318px !important;
    }
}

.reward-flight-filter-city-wrapper,
.reward-flight-filter-airport-wrapper {
    .semi-select-group {
        padding-top: 8px !important;
    }

    .semi-select-option-list > .semi-select-group:first-child {
        padding-top: 4px !important;
    }
}

.reward-flights-filter-sidesheet-wrapper {
    .semi-sidesheet-header {
        display: none;
    }
}

.reward-flight-filter-point-wrapper {
    .semi-slider {
        width: 100%;
        padding: 0;

        .semi-slider-marks {
            .semi-slider-mark {
                font-weight: 600;
                font-size: 10px;
            }

            .semi-slider-mark:first-child {
                transform: none;
            }

            .semi-slider-mark:last-child {
                transform: translate(-100%, 0);
            }
        }


        .semi-slider-handle {
            height: 16px;
            width: 16px;
            position: relative;
            top: 4px;
        }

        .semi-slider-dots {
            .semi-slider-dot:first-child {
                display: none;
            }
        }
    }
}