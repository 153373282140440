.App_mobile {
    .product-detail-wrapper {
        position: relative;
    
        .semi-tabs-bar {
            position: sticky;
            top: 0;
            z-index: 10;
            background: white;
            padding: 0 16px;
        }

        .semi-tabs-tab {
            font-family: "Poppins", sans-serif;
            font-weight: 600;
        }
    }
}

.App_embedded {
    .product-detail-wrapper {
        position: relative;
    
        .semi-tabs-bar {
            position: sticky;
            top: 0;
            z-index: 10;
            background: white;
            // padding: 0 16px;
        }

        .semi-tabs-tab {
            font-family: "Poppins", sans-serif;
            font-weight: 600;
        }
    }
}

.App_dark {
    .product-detail-wrapper {
        .semi-tabs-bar {
            background: rgba(53, 54, 60, 1) !important;
        }
    }
}

