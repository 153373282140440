.reward-hotel-map-wrapper{
    .dot {
        width: 18px;
        height: 18px;
        background-color: #3498db;
        border-radius: 50%;
        position: absolute;
        animation: fadeOut 2s ease-out infinite;
        z-index: 1;
        top: 0;
        left: 0
    }

    @keyframes fadeOut {
        0% {
            opacity: 0.5;
            transform: scale(1);
        }

        100% {
            opacity: 0;
            transform: scale(3);
        }
    }

    .destination-airport-node-wrapper {
        cursor: pointer;

        &:hover {
            transform: scale(1.4)
        }
    }
    .mapboxgl-popup-content {
        padding: 0 !important;
        font-family: "Poppins", sans-serif !important;
        border-radius: 8px !important;
        overflow: hidden !important;
    }

    .mapboxgl-popup-tip {
        transform: scale(0.5);
        transform-origin: bottom;   
    }

    .dot {
        width: 18px;
        height: 18px;
        background-color: var(--semi-color-primary);
        border-radius: 50%;
        position: absolute;
        animation: dot-animation 2s ease-out infinite;
        z-index: 1;
        top: 0;
        left: 0
    }

    @keyframes dot-animation {
        0% {
            opacity: 0.5;
            transform: scale(1);
        }

        100% {
            opacity: 0;
            transform: scale(5);
        }
    }
}

.App_dark {
    .reward-hotel-map-wrapper{
    
        .mapboxgl-popup-content {
            background: rgba(53, 54, 60, 1) !important;
        }

        .mapboxgl-popup-tip {
            border-bottom-color: rgba(53, 54, 60, 1) !important;
        }
    }
}