.admin-wrapper {
    .semi-navigation-header-list-outer {
        overflow: auto;
    }

    .semi-tabs-bar {
        padding-left: 16px;

        .semi-tabs-tab {
            padding: 12px 0;
        }
    }

    .semi-tabs-bar-extra {
        height: 46px !important;
    }
}