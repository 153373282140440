.login-wrapper {
    .semi-form-field-label-text {
        font-family: "Poppins", sans-serif;
    }

    .semi-input-wrapper input {
        font-family: "Poppins", sans-serif;
    }

    .semi-form-field-error-message {
        font-weight: 600;
    }
}

#sign-in-with-google-btn {
    border-color: lightgrey !important;
    background: white;
}